/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { useEffect, useState } from 'react';
import { Button, ExtendedStepper, StepProps, Tooltip } from '@perf/ui-components';
import THUMB_UP from '@app/img/svg/thumb_up.svg';
import StatementsSurvey from '@components/common/statements-survey/statements-survey.component';
import DummyResult from '@components/element/dummy-result/dummy-result.component';
import { APP_REVIEW_ROLES, INDICATORS_TYPES, VISIBLE_CONDITION } from '@lib/common.constants';
import { JSService } from '@services/js.service';
import { ModalService } from '@services/modal.service';
import { ScaleService } from '@services/scale.service';
import { AssessmentStatement, CatalogDimension, IScoreDetailList, IVisibleCondition, IVoter, ScoreDetail } from '@root/src/types/catalog.types';
import { IPermissionRoles, User } from '@root/src/types/user.types';
import { Review } from '@root/src/types/review.types';
import { useAssessmentStore } from '@root/src/store/use.assessment.store';
import { setProgress, updateReviewDimensionsAction } from '@root/src/store/assessment.actions';
import { AnonymsAssessmentActions, useAnonymsAssessmentStore } from '@root/src/store/use.anonyms-assessment.store';
import { setAnonymsAssessmentState, setAnonymsProgress } from '@root/src/store/anonyms-assessment.actions';
import { modifyDimensionData } from '@components/views/assessment-view/assessment-view.utils';
import { AssessmentState } from '@root/src/store/store.types';
import { convertDimensionObjectToArray } from '@root/src/utils/dimensions.utils';

interface IDynamicSurveyProps {
  dimensions?: CatalogDimension[];
  controllableStep?: number;
  previewMode?: boolean;
  user: User;
  voter: IVoter;
  roles: APP_REVIEW_ROLES[] | IPermissionRoles[];
  anonymous?: boolean;
  activeDimensionId: string;
  onUpdate?: (activeStepId: string) => void;
  onFinish: () => void;
  synchronizeStepsNum?: (numSteps: number) => void;
  synchronizeStep?: (step: number) => void;
  onTabActivate?: () => void;
  isEditable: boolean;
  isDocumentsAvailable: boolean;
  reviewId?: string;
  isFinished?: boolean;
  onSave: (updatedReview?: Review) => void;
  isCompleted?: boolean;
}

const DynamicSurvey = ({
  dimensions: dimensionsProps,
  controllableStep,
  previewMode,
  user,
  voter,
  roles,
  anonymous,
  activeDimensionId,
  onUpdate,
  onFinish,
  synchronizeStepsNum,
  synchronizeStep,
  onTabActivate,
  isEditable,
  isDocumentsAvailable,
  reviewId,
  isFinished,
  onSave,
  isCompleted,
}: IDynamicSurveyProps) => {
  const [steps, setSteps] = useState<{ dimensionId: string, label: string }[]>([]);
  const [statements, setStatements] = useState<AssessmentStatement[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [prevControllableStep, setPrevControllableStep] = useState<number | undefined>();

  useEffect(() => {
    if (previewMode) {
      synchronizeStepsNum?.(filterEmptySteps(steps).length);
    }
  }
  , [steps, statements]);

  const { isReviewDataPrepared } = useAssessmentStore((state) => ({
    isReviewDataPrepared: (state as AssessmentState).assessmentState.isReviewDataPrepared,
  }));

  const handleProgress = (ignoreSave?: boolean, updatedStatements?: AssessmentStatement[], dimensionId?: string) => anonymous ? setAnonymsProgress(ignoreSave, updatedStatements, dimensionId) : setProgress(ignoreSave, updatedStatements, dimensionId, true);

  const getTargetDimensions = () => {
    if (anonymous) {
      const { review } = useAnonymsAssessmentStore.getState().assessmentState;

      return structuredClone(review?.dimensions);
    }
    const { review } = useAssessmentStore.getState().assessmentState;

    return previewMode ? dimensionsProps as CatalogDimension[]: structuredClone(review?.dimensions);
  };

  const componentOnInit = (isActiveStepReset?: boolean) => {
    const dimensions = getTargetDimensions();

    if (dimensions?.length) {
      prepareSteps(dimensions);

      if (previewMode) {
        handleActiveStep(controllableStep || 0);
      }
      handleActiveStep(activeStep || 0);

      if (dimensions) {
        prepareStatements(dimensions);
        selectActiveStep(dimensions, !!isActiveStepReset);
      }
    }
  };

  const prepareStatements = (currentSteps: CatalogDimension[]) => {
    const preparedStatements = specialGetStatements(currentSteps);

    initStatementsVisibility(preparedStatements);
  };

  useEffect(() => {
    if (previewMode || isReviewDataPrepared || anonymous) {
      componentOnInit();
    }
  }, [isReviewDataPrepared, dimensionsProps]);

  useEffect(() => {
    if (controllableStep !== prevControllableStep) {
      setPrevControllableStep(controllableStep);
      handleActiveStep(controllableStep || 0);
    }
  }, [controllableStep]);

  const selectActiveStep = (targetSteps: CatalogDimension[], isActiveStepReset: boolean) => {
    if (isActiveStepReset) {
      return handleActiveStep(0);
    }

    let updatedActiveStep = activeStep || 0;
    if (!previewMode) {
      targetSteps.forEach((step, index) => {
        if (step.id === activeDimensionId) {
          updatedActiveStep = index;
        }
      });
    }
    handleActiveStep(updatedActiveStep);
  };

  const filterEmptySteps = (targetSteps: { dimensionId: string, label: string }[]) => {
    const dimensions = getTargetDimensions();

    return targetSteps.filter(({ dimensionId }) => {
      const targetDimension = dimensions.find(dimension => dimension.id === dimensionId) as CatalogDimension;

      return targetDimension.statements.find((statement) => {
        if (previewMode) {
          const targetStatement = statements.find(({ id }) => id === statement.id);
          return targetStatement?.visible;
        }
        return statement.visible;
      });
    });
  }

  const specialGetStatements = (currentSteps: CatalogDimension[]): AssessmentStatement[] => currentSteps.reduce((targetStatements, step) => {
      const statementsWithDefaultVisibility = saveDefaultVisibility(step.statements);
      const statementWithParent = setStatementsParent(statementsWithDefaultVisibility, step.id, step.name);
      return [...targetStatements, ...statementWithParent];
    }, [] as AssessmentStatement[]);

  const setStatementsParent = (targetStatements:  AssessmentStatement[], parentId: string, parentName: string) => targetStatements.map((statement) => {
      statement.parent = {
        id: parentId,
        name: parentName,
      };
      return statement;
    });

  const saveDefaultVisibility = (statements: AssessmentStatement[]) => statements.map((statement) => {
      statement.defaultVisible = JSService.getObjectCopy(statement).visible;
      return statement;
    });

  const prepareSteps = (initialData: CatalogDimension[]) => {
    const targetData = previewMode ? convertDimensionObjectToArray(initialData) : initialData;

    const sortedData = targetData
      .sort((dimensionA, dimensionB) => dimensionA.order - dimensionB.order)
      .map((dimension) => ({
          dimensionId: dimension.id,
          label: dimension.name,
        }));

    setSteps(sortedData);
  };

  const initStatementsVisibility = (preparedStatements: AssessmentStatement[]) => {
    preparedStatements.forEach((statement) =>
      handleVisibility(preparedStatements, statement),
    );
  };

  const handleVisibility = (preparedStatements: AssessmentStatement[], updatedStatement: AssessmentStatement) => {
    const checkedStatements = preparedStatements.map((statement) => {
      if (statement.visibleIf) {
        // OR Condition
        const allConditions = [
          ...(statement.visibleIf?.AND ?? []),
          ...(statement.visibleIf?.OR ?? []),
        ];
        const associatedCondition = getAssociatedCondition(allConditions, updatedStatement);

        if (statement.visibleIf.OR?.length > 0 && associatedCondition && allConditions?.length) {
          return handleOrConditionCase(statement, allConditions, preparedStatements);
        }
        return handleAndConditionCase(statement, updatedStatement, preparedStatements);
      }
      return statement;
    });

    updateStatementData(checkedStatements);
  };

  const updateStatementData = (updatedStatements: AssessmentStatement[]) => {
    const dimensions = getTargetDimensions();

    const targetDimensions = structuredClone(dimensions);
    const updatedDimensions = targetDimensions.reduce((acc, dimension) => modifyDimensionData(
        acc,
        updatedStatements,
        dimension.id,
      ), targetDimensions);

    if (anonymous) {
      const { review } = useAnonymsAssessmentStore.getState().assessmentState;

      setAnonymsAssessmentState({
        review: {
          ...review,
          dimensions: updatedDimensions,
        }
      }, AnonymsAssessmentActions.setReview);
    } else {
      updateReviewDimensionsAction(updatedDimensions);
    }


    setStatements(updatedStatements);
  };

  const handleOrConditionCase = (statement: AssessmentStatement, allConditions: IVisibleCondition[], preparedStatements: AssessmentStatement[]) => {
    const oneOfAndConditionFalse = Object.values(
      checkCondition(VISIBLE_CONDITION.AND, statement, preparedStatements, allConditions.length),
    ).includes(false);

    // If one of AND condition is FALSE then check intersection for each AND = FALSE inside OR conditions
    // If all conditions with OR = TRUE cover all conditions with AND = FALSE for the same `catalogStatement.id` then rule is visible
    const isVisible =
      !oneOfAndConditionFalse ||
      checkObjects(
        checkCondition(VISIBLE_CONDITION.AND, statement, preparedStatements),
        checkCondition(VISIBLE_CONDITION.OR, statement, preparedStatements),
      );

    statement.visible = isVisible;
    if (!isVisible) {
      return resetUserVote(statement);
    };
    return statement;
  };

  const handleAndConditionCase = (statement: AssessmentStatement, updatedStatement: AssessmentStatement, statements: AssessmentStatement[]) => {
    const allConditions = [...statement.visibleIf.AND];
    const associatedCondition = getAssociatedCondition(allConditions, updatedStatement);

    if (associatedCondition && allConditions?.length) {
      const oneOfConditionFalse = Object.values(checkCondition(
        VISIBLE_CONDITION.AND,
        statement,
        statements,
        allConditions.length,
      )).includes(false);

      statement.visible = !oneOfConditionFalse;

      if (oneOfConditionFalse) {
        return resetUserVote(statement);
      };
    }
    return statement;
  };

  const checkCondition = (method: VISIBLE_CONDITION, statement: AssessmentStatement, preparedStatements: AssessmentStatement[], conditionsCount?: number) => {
    const result = {} as {[key: string]: boolean};

    [...statement.visibleIf[method]].forEach((condition) => {
      const isPositive = checkAnswer(
        findStatementByCondition(preparedStatements, condition) as AssessmentStatement,
        condition,
        conditionsCount,
      );
      result[condition.catalogStatement.id] = isPositive;
    });
    return result;
  };

  const findStatementByCondition = (preparedStatements: AssessmentStatement[], condition: IVisibleCondition) =>
    preparedStatements?.find(
      (st) =>
        st?.id === condition.catalogStatement.id ||
        st?.catalogStatementId === condition?.catalogStatement.id,
    );

  const checkAnswer = (statementToCheck: AssessmentStatement, conditionToCheck: IVisibleCondition, conditionsCount = 0) => {
    if (!statementToCheck) {
      return false;
    }
    const answer =
      !ScaleService.isRadioGroup(statementToCheck.type) &&
      !ScaleService.isMultiGroup(statementToCheck.type)
        ? conditionToCheck.answer.toUpperCase()
        : conditionToCheck.answer;

    const voterIndex = getVoterIndex(statementToCheck.userScores);
    const score = ((statementToCheck.userScores || {})[user.id] || [])[voterIndex] || {};

    if (ScaleService.isMultiGroup(statementToCheck.type)) {
      if (!score.userChoices) {
        return false;
      }
      return conditionsCount === score.userChoices.length && score.userChoices.includes(answer);
    }
    return answer === score.userScore;
  };

  const getVoterIndex = (userScores: IScoreDetailList = {}) =>
    (userScores[user.id] || []).findIndex(
      (voterItem) => voterItem.voterId === voter.id,
    );

  const getAssociatedCondition = (allConditions: IVisibleCondition[], updatedStatement: AssessmentStatement) =>
    allConditions?.find(
      (e) =>
        e.catalogStatement.id === updatedStatement.catalogStatementId ||
        e.catalogStatement?.id === updatedStatement.id,
    );

  const checkObjects = (obj1: {[key: string]: boolean}, obj2: {[key: string]: boolean}) => {
    for (const key in obj1) {
      if (!obj1[key] && !obj2[key]) {
        return false;
      }
    }
    return true;
  };

  const resetUserVote = (statement: AssessmentStatement) => {
    const voterIndex = getVoterIndex(statement.userScores);
    const updatedStatement = structuredClone(statement);
    if (voterIndex > -1) {
      updatedStatement.userScores[user.id][voterIndex].userScore = null;

      if (updatedStatement.userScores[user.id][voterIndex].userChoices) {
        updatedStatement.userScores[user.id][voterIndex].userChoices = [];
      }
    }
    return updatedStatement;
  };

  const isCompletedSurvey = (votedSurvey: IVoter) => votedSurvey.finishDate;

  const handleBack = () => {
    if (previewMode) {
      synchronizeStep?.(activeStep - 1);
    } else if (anonymous) {
      handleActiveStep(activeStep - 1);
    } else {
      handleActiveStep(activeStep - 1);
      const dimensionId = getActiveStepId(activeStep - 1);
      onUpdate?.(dimensionId);
    }
  };

  const getActiveStepId = (currentActiveStep: number) => steps[currentActiveStep].dimensionId;

  const handleReset = () => {
    if (previewMode || anonymous) {
      handleActiveStep(0);
      resetStatements(false);
    } else {
      handleActiveStep(0);
      resetStatements(true);
    }
  };

  const resetStatements = (isProgressUpdate: boolean) => {
    const updatedStatements = statements.map((statement) => {
      statement.visible = statement.defaultVisible as boolean;
      return resetUserVote(statement);
    });

    updateStatementData(updatedStatements);
    handleProgress(true);

    if (isProgressUpdate) {
      onUpdate?.(getActiveStepId(0));
      componentOnInit(true);
    }
  };

  const isNextDisabled = () => {
    if (previewMode) {
      return activeStep === getStepsLength() - 1;
    }
    return (
      activeStep === getStepsLength() - 1 ||
      statementsAreNotCompleted(activeStep)
    );
  };

  const handleNext = () => {
    if (previewMode) {
      synchronizeStep?.(activeStep + 1);
    } else if (anonymous) {
      handleActiveStep(activeStep + 1);
    } else {
      handleActiveStep(activeStep + 1);
      onUpdate?.(getActiveStepId(activeStep + 1));
    }
  };

  const isFinishDisabled = () =>
    activeStep !== getStepsLength() - 1 ||
    statementsAreNotCompleted(activeStep);

  const getStepsLength = () => filterEmptySteps(steps).length;

  const statementsAreNotCompleted = (activeStep: number) => {
    const dimensions = getTargetDimensions();

    let hasError = false;
    const filteredSteps = filterEmptySteps(steps);
    const targetStep = filteredSteps[activeStep];
    const isReviewExpert = isUserReviewExpert(roles as APP_REVIEW_ROLES[]);
    const userId = user?.id;
    const statements = dimensions?.find((dimension) => dimension.id === targetStep.dimensionId)?.statements;

    if (!statements?.length) return hasError;

    statements.forEach((statement) => {
      const statementUserScores = statement.userScores?.[userId];
      const hasValue = hasScoreValue(statement, statementUserScores);

      if (anonymous) {
        const multiGroupHasError = isAnonymousMultiGroupError(
          statement,
          statementUserScores,
          isReviewExpert,
        );

        hasError = !hasError
          ? checkErrorForAnonymous(statement, hasValue, multiGroupHasError)
          : hasError;
      } else {
        const voterIndex = getVoterIndex(statement.userScores);
        const voterUserScore = voterIndex > -1 && (statementUserScores[voterIndex] || {});
        const multiGroupVoterError = isMultiGroupError(
          statement,
          voterUserScore as ScoreDetail,
          isReviewExpert,
        );

        hasError = !hasError
          ? checkErrorForNonAnonymous(statement, voterUserScore as ScoreDetail, multiGroupVoterError)
          : hasError;
      }
    });

    return hasError;
  };

  const handleActiveStep = (activeStep: number) => {
    setActiveStep(activeStep);

    if (previewMode) {
      synchronizeStep?.(activeStep)
    }
  };

  const handleFinish = () => {
    openAcceptModal(() => onFinish());
  };

  const openAcceptModal = (callback: () => void) => {
    ModalService.openAcceptModal({
      title: 'Finish survey',
      description: 'Are you sure you want to finish the Survey?',
      onAccept: callback,
    });
  };

  const hasScoreValue = (statement: AssessmentStatement, userScores: ScoreDetail[]) =>
    ScaleService.isOpenEnded(statement.type)
      ? userScores?.[0]?.userChoices?.length
      : userScores?.[0]?.userScore;

  const isAnonymousMultiGroupError = (statement: AssessmentStatement, userScores: ScoreDetail[], isReviewExpert: boolean) =>
    statement.rated && isReviewExpert
      ? !userScores?.[0]?.userScore
      : !userScores?.[0]?.userChoices?.length;

  const checkErrorForAnonymous = (statement: AssessmentStatement, hasValue: number | INDICATORS_TYPES | null, multiGroupHasError: boolean) =>
    statement.visible &&
    (ScaleService.isMultiGroup(statement.type) ? multiGroupHasError : !hasValue);

  const isMultiGroupError = (statement: AssessmentStatement, voterUserScore: ScoreDetail, isReviewExpert: boolean) =>
    statement.rated && isReviewExpert
      ? !voterUserScore.userScore
      : !voterUserScore.userChoices?.length;

  const checkErrorForNonAnonymous = (statement: AssessmentStatement, voterUserScore: ScoreDetail, multiGroupHasError: boolean) =>
    statement.visible &&
    (!ScaleService.isMultiGroup(statement.type) && !ScaleService.isOpenEnded(statement.type)
      ? !voterUserScore.userScore
      : multiGroupHasError);

  const isUserReviewExpert = (roles: APP_REVIEW_ROLES[]) => (roles || []).includes(APP_REVIEW_ROLES.REVIEW_EXPERT);

  return (
    <>
      {!!JSService.getObjectLength(steps) && (
        <div className="survey-stepper">
          <ExtendedStepper
            key={`steps-${filterEmptySteps(steps).length}`}
            disabled={!previewMode}
            linear={false}
            activeStep={activeStep}
            steps={filterEmptySteps(steps) as StepProps[]}
            className="survey-stepper--header"
            onStepChange={(step) => handleActiveStep(step)}
          />
          <section className="survey-stepper--body">
            {isCompletedSurvey(voter) ? (
              <DummyResult
                src={THUMB_UP}
                message="All Done!"
                description="Thank you, you've completed the entire Survey."
                button={{
                  name: 'View Results',
                  type: 'primary',
                  onClick: onTabActivate,
                }}
                big
              />
            ) : (
              <>
                {!!JSService.getObjectLength(statements) && (
                  <StatementsSurvey
                    initialStatements={statements}
                    dimensionId={
                      filterEmptySteps(steps)?.[activeStep]?.dimensionId
                    }
                    user={user}
                    voter={voter}
                    onProgressSet={(ignoreSave, updatedStatements, dimensionId) => handleProgress(ignoreSave, updatedStatements, dimensionId)}
                    onVisibleChange={(statements, updatedStatement) =>
                      handleVisibility(statements, updatedStatement)
                    }
                    isEditable={isEditable}
                    isSurveyType
                    parentId={filterEmptySteps(steps)[activeStep]?.dimensionId}
                    previewMode={previewMode}
                    roles={roles as APP_REVIEW_ROLES[]}
                    anonymous={!!anonymous}
                    isDocumentsAvailable={isDocumentsAvailable}
                    reviewId={reviewId || ''}
                    isFinished={!!isFinished}
                  />
                )}
                {!previewMode && (
                  <section className="survey-stepper--controls">
                    <div className="survey-stepper--controls-left">
                      <Button
                        disabled={activeStep === 0}
                        variant="outlined"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button variant="outlined" onClick={handleReset}>
                        Clear Answers
                      </Button>
                    </div>
                    <div className="survey-stepper--controls-right">
                      <Button disabled={isNextDisabled()} onClick={handleNext}>
                        Next
                      </Button>
                      {!anonymous && (
                        <Button
                          type="success"
                          disabled={isFinishDisabled()}
                          onClick={handleFinish}
                        >
                          Finish
                        </Button>
                      )}
                      {anonymous && (
                        <Tooltip
                          content="To finish the survey you must answer all the questions"
                          placement="top"
                          disabled={isCompleted}
                        >
                          <div style={{ display: 'inline-flex' }}>
                            <Button
                              type="success"
                              disabled={!isCompleted}
                              onClick={onSave as () => void}
                            >
                              Send
                            </Button>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </section>
                )}
              </>
            )}
          </section>
        </div>
      )}
    </>
  );
};

export default DynamicSurvey;
