/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AnonymsAssessmentStateInterface } from '@app/store/store.types';
import { ANONYMOUS_USER_ID, USER_STATE } from '@lib/common.constants';
import { Permission } from '@app/types/user.types';
import { Review } from '@app/types/review.types';

const STORE_KEYS = {
  useAnonymsAssessmentStore: 'useAnonymsAssessmentStore',
};

export enum AnonymsAssessmentActions {
  setReview = 'setReview',
  userProgress = 'userProgress',
  setVoter = 'setVoter',
  updateOpenedIDs = 'updateOpenedIDs',
  setReviewId = 'setReviewId',
  isManualRedirected = 'isManualRedirected',
  isAllDimensionsOpened = 'isAllDimensionsOpened',
  submitAnotherResponse = 'submitAnotherResponse',
  setErrors = 'setErrors',
  setIsCompleted = 'setIsCompleted',
  setOptions = 'setOptions',
  loadAssessment = 'loadAssessment',
  handleAuthorNameInput = 'handleAuthorNameInput',
  toggleReviewExpanded = 'toggleReviewExpanded',
  handleAuthorJobInput = 'handleAuthorJobInput',
  toggleReviewExpand = 'toggleReviewExpand',
};

export const initialAnonymsAssessmentState = {
  reviewId: '',
  review: {} as Review,
  user: {
    id: ANONYMOUS_USER_ID,
    email: '',
    name: 'anonymous',
    roles: [],
    state: USER_STATE.ACTIVE,
    avatarUrl: undefined,
    lastLogin: null,
    permissions: {} as Permission,
    globalAdmin: false,
    roleInUnit: null,
  },
  userProgress: 0,
  level: 1,
  userScoresCopy: {},
  reviewVotesAreSet: false,
  isManualRedirected: false,
  authorName: '',
  authorJob: '',
  errors: {},
  isCompleted: false,
  voter: null,
  isAllDimensionsOpened: true,
  options: {} as any,
  openedIDs: [],
};

export const useAnonymsAssessmentStore = create(
  devtools(
    () => ({
      assessmentState: initialAnonymsAssessmentState as AnonymsAssessmentStateInterface,
    }),
    { name: STORE_KEYS.useAnonymsAssessmentStore },
  ),
);
