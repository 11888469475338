/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */

import { Tooltip } from '@perf/ui-components';
import { clearButtonStyles } from '@components/common/survey-scale/survey-clear-button/survey-clear-button.style';
import { Colors } from '@root/src/lib/colors.constants';
import { DeleteLightIcon } from '@root/src/img/jsx-wrapped-images/delete-light.component';

interface SurveyClearButtonProps {
  message?: string;
  onReset: () => void;
  size?: number;
}

const SurveyClearButton = ({ message, onReset, size = 18 }: SurveyClearButtonProps) => (
    <Tooltip
      content={message || 'Reset selections'}
      placement="top"
    >
      <div css={clearButtonStyles(size)}>
        <DeleteLightIcon onClick={onReset} color={Colors.disabled} />
      </div>
    </Tooltip>
  );

export default SurveyClearButton;
